import { LandingVersion2 } from "../components/landing/test2/LandingVersion2";
import { Helmet } from "react-helmet-async";

const DEBUG = false;

export const Landing = ({ abTests }) => {
  const testResult = abTests[0]?.test_result;
  return (
    <>
      <Helmet>
        <title>
          Quick Home Improvement - An AI Assistant for Home Improvement
        </title>
        <meta
          name="description"
          content="Welcome to Quick Home Improvement, your AI Assistant for home tasks."
        />
        <link rel="canonical" href="https://www.quickhomeimprovement.com/" />
      </Helmet>
      <LandingVersion2 />
    </>
  );
};
