import React from "react";
import styled from "styled-components";
import { PrettyButton } from "../styles/styledComponents";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 80%;
  max-width: 500px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ModalHeader = styled.h3`
  margin-top: 0;
  color: #333;
`;

const ModalBody = styled.p`
  color: #666;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;

const LoginModal = () => {
  const { loginWithRedirect } = useAuth0();

  const directToLogin = () => {
    loginWithRedirect();
  };
  return (
    <ModalBackdrop>
      <ModalContent>
        <ModalHeader>Out of Free Messages</ModalHeader>

        <ModalBody>Login to get more!</ModalBody>

        <PrettyButton
          onClick={() => directToLogin()}
          style={{ width: "180px" }}
        >
          Login
        </PrettyButton>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default LoginModal;
