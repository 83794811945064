import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Plus, Trash2, ChevronDown, Edit2, Save } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getTodos,
  saveTodo,
  editTodo,
  deleteTodo,
} from "../../functions/apiFunctions";

export const ToDo = ({ abTests }) => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();
  const [tasks, setTasks] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editText, setEditText] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(!isAuthenticated);

  useEffect(() => {
    const fetchInitialTodos = async () => {
      try {
        const token = await getAccessTokenSilently();
        const results = await getTodos(token, abTests);
        const todos = results.todos;
        console.log("[useEffect] todos", todos);
        setTasks(todos);
      } catch (error) {
        console.error("Error fetching initial todos:", error);
      }
    };

    console.log("[useEffect] Fetching initial todos");
    fetchInitialTodos();
  }, [getAccessTokenSilently, abTests]);

  const handleSaveTask = async (index) => {
    console.log("[handleSaveTask]");
    console.log("[handleSaveTask] index", index);
    console.log("[handleSaveTask] editIndex", editIndex);
    console.log("[handleSaveTask] editText", editText);
    let taskText = editText.trim();

    if (taskText === "") {
      taskText = "New Task";
      setEditText(taskText);
    }

    let updatedTask = { task: { title: taskText } };

    console.log("[handleSaveTask] updatedTask", updatedTask);

    const updatedTasks = [...tasks];
    updatedTasks[index] = updatedTask;

    let taskUpdate = false;
    if (tasks[index]?.id) {
      taskUpdate = true;
    }

    setTasks(updatedTasks);
    setEditIndex(-1);
    setEditText("");

    const token = await getAccessTokenSilently();

    if (taskUpdate) {
      console.log("[handleSaveTask] updating existing task");
      await editTodo(token, tasks[index].id, updatedTask.task);
    } else {
      console.log("[handleSaveTask] creating new task");
      const taskId = await saveTodo(token, updatedTask.task);
      console.log("[handleSaveTask] taskId", taskId);

      updatedTask["id"] = taskId.todo_id;
      updatedTasks[index] = updatedTask;
      setTasks(updatedTasks);
    }
  };

  const handleDeleteTask = async (index) => {
    const taskToDelete = tasks[index];
    console.log("[handleDeleteTask]", taskToDelete);
    const newTasks = tasks.filter((_, i) => i !== index);
    setTasks(newTasks);
    const token = await getAccessTokenSilently();
    const taskId = taskToDelete.id;
    console.log("[handleDeleteTask]taskId", taskId);

    await deleteTodo(token, taskToDelete.id);
  };

  const handleAddTask = () => {
    setTasks([...tasks, { task: { title: "New Task" } }]);
    setEditIndex(tasks.length);
    setEditText("New Task");
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <ToDoContainer>
      <ToDoHeader onClick={toggleCollapse}>
        <SectionTitle>To-Do List</SectionTitle>
        <ChevronDown
          size={20}
          style={{
            transform: isCollapsed ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        />
      </ToDoHeader>
      {!isCollapsed && (
        <>
          <ToDoList>
            {tasks.map((task, index) => (
              <ToDoItem key={task.id || index}>
                {editIndex === index ? (
                  <EditInput
                    type="text"
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    onBlur={() => handleSaveTask(index)}
                    autoFocus
                  />
                ) : (
                  <ToDoText>{task.task?.title || task.title}</ToDoText>
                )}
                <ButtonGroup>
                  {editIndex !== index && (
                    <ActionButton
                      onClick={() => {
                        setEditIndex(index);
                        setEditText(task.task?.title || task.title);
                      }}
                      aria-label={`Edit "${task.task?.title || task.title}"`}
                    >
                      <Edit2 size={16} />
                    </ActionButton>
                  )}
                  {editIndex === index && (
                    <ActionButton
                      onClick={() => handleSaveTask(index)}
                      aria-label={`Save "${editText}"`}
                    >
                      <Save size={16} />
                    </ActionButton>
                  )}
                  <ActionButton
                    onClick={() => handleDeleteTask(index)}
                    aria-label={`Delete "${task.task?.title || task.title}"`}
                  >
                    <Trash2 size={16} />
                  </ActionButton>
                </ButtonGroup>
              </ToDoItem>
            ))}
          </ToDoList>
          {isAuthenticated ? (
            <AddTaskButton onClick={handleAddTask}>
              <Plus size={20} />
              Add Task
            </AddTaskButton>
          ) : (
            <AddTaskButton onClick={handleLogin}>
              Sign in for save a task
            </AddTaskButton>
          )}
        </>
      )}
    </ToDoContainer>
  );
};

const ToDoContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  overflow: hidden;
`;

const ToDoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f8f9fa;
  cursor: pointer;
  user-select: none;
`;

const SectionTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

const ToDoList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 300px; /* Set the max height */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const ToDoItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid #e9ecef;

  &:last-child {
    border-bottom: none;
  }
`;

const ToDoText = styled.span`
  flex-grow: 1;
  font-size: 0.9rem;
  color: #333;
`;

const EditInput = styled.input`
  flex-grow: 1;
  font-size: 0.9rem;
  padding: 4px 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #e9ecef;
    color: #343a40;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
`;

const AddTaskButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }

  svg {
    margin-right: 8px;
  }
`;

export default ToDo;
