import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Home, Package, ArrowRight } from "lucide-react";
import { useEffect } from "react";

export const NavigationStatic = ({ isStarter = false }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Current URL:", window.location.href);
  }, []);

  return (
    <Container>
      <Navbar>
        <LogoContainer onClick={() => navigate("/")}>
          <Home size={24} />
          <BrandText>Quick Home Improvement</BrandText>
        </LogoContainer>
        <NavItems>
          <NavLink onClick={() => navigate("/starters")}>
            <Package size={18} />
            Starter Packs
          </NavLink>
          <GetStartedButton onClick={() => navigate("/")}>
            Chat
            <ArrowRight size={18} />
          </GetStartedButton>
        </NavItems>
      </Navbar>
    </Container>
  );
};

const Container = styled.header`
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Navbar = styled.nav`
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const BrandText = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const NavLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const GetStartedButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #0070f3;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background-color: #0060df;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;
